import { StudentDTO, StudentWithAttendanceDTO } from "src/dto";

export const util = {
  getDateObj: (dateTime = new Date()) => {
    const today = new Date(dateTime);
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const _month = month >= 10 ? `${month}` : `0${month}`;
    const date = today.getDate();
    const _date = date >= 10 ? `${date}` : `0${date}`;
    const hour = today.getHours();
    const minute = today.getMinutes();
    const ampm: "오후" | "오전" = hour > 11 ? "오후" : "오전";
    return {
      year,
      month,
      _month,
      date,
      _date,
      hour,
      minute,
      ampm,
    };
  },
  getDateToString: (today = new Date()) => {
    const { month, date, hour, minute, ampm } = util.getDateObj(today);

    return `${month >= 10 ? month : `0${month}`}월 ${
      date >= 10 ? date : `0${date}`
    }일 ${hour >= 10 ? hour : `0${hour}`}:${
      minute >= 10 ? minute : `0${minute}`
    } ${ampm}`;
  },
  koGrade: (grade: StudentDTO["grade"]) => {
    switch (grade) {
      case 1:
        return "초1";
      case 2:
        return "초2";
      case 3:
        return "초3";
      case 4:
        return "초4";
      case 5:
        return "초5";
      case 6:
        return "초6";
      case 7:
        return "중1";
      case 8:
        return "중2";
      case 9:
        return "중3";
      case 10:
        return "고1";
      case 11:
        return "고2";
      case 12:
        return "고3";
    }
  },
  getStringToday: () => {
    const { year, _month, _date } = util.getDateObj();
    return `${year}-${_month}-${_date}`;
  },
  getTodayKo: () => {
    const { month, _date, hour, minute, ampm } = util.getDateObj();

    const _hour = (function () {
      if (ampm === "오전") {
        return hour === 12 ? 0 : hour;
      } else {
        return hour > 12 ? hour - 12 : 12;
      }
    })();

    return `${month}월 ${_date}일 ${_hour < 10 ? `0${_hour}` : _hour}:${
      minute < 10 ? `0${minute}` : minute
    } ${ampm}`;
  },
  sortStudentList: (
    studentList: StudentWithAttendanceDTO[]
  ): StudentWithAttendanceDTO[] => {
    // 출석하면 위로 - 출석 시간이 최근일수록 우선
    // 이름 asc

    let attendancedStudentList: StudentWithAttendanceDTO[] = [];
    const defaultList: StudentWithAttendanceDTO[] = [];

    studentList.forEach((student) => {
      if (student.enterDatetime) {
        attendancedStudentList.push(student);
      } else {
        defaultList.push(student);
      }
    });

    attendancedStudentList = attendancedStudentList.sort((cur, pre) => {
      const curDate = new Date(cur.enterDatetime as Date).getTime();
      const prevDate = new Date(pre.enterDatetime as Date).getTime();
      return prevDate - curDate;
    });

    return [...attendancedStudentList, ...defaultList];
  },
};
