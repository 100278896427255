import "react-toastify/dist/ReactToastify.css";

import { observer } from "mobx-react";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { attendanceDomain, userDomain } from "./domain";
import { CustomModal } from "./modal";
import { DetailedStudentListPage, LoginPage, MainPage } from "./pages";

const App = () => {
  React.useEffect(() => {
    attendanceDomain.initIsViewStudentList();
  }, []);

  return (
    <Suspense fallback={<></>}>
      <div className="app">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          {userDomain.isAutoLogined && (
            <>
              <Route path="/main" element={<MainPage />} />
              <Route
                path="/student-list"
                element={<DetailedStudentListPage />}
              />
            </>
          )}
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <CustomModal />
    </Suspense>
  );
};

export default observer(App);
