import './EnterExitManageModal.scss';

import { observer, useLocalObservable } from 'mobx-react';
import React from 'react';
import { Toggle } from 'src/components/Toggle';
import { academyDomain } from 'src/domain';

const EnterExitManageModal = () => {
  const store = useLocalObservable(() => ({
    checked: academyDomain.isSmsSent,
    toggleChecked: () => {
      const changedSmsSent = !store.checked;
      store.checked = changedSmsSent;
      academyDomain.patchAcademyMetadata(changedSmsSent);
      academyDomain.updateAcademyMetadata(changedSmsSent);
    },
  }));

  return (
    <div className="enter-exit-manage__modal">
      <h3 className="enter-exit-manage__title">입퇴실 문자 관리</h3>
      <div className="enter-exit-manage__toggle">
        <Toggle.Label
          checked={store.checked}
          onChange={store.toggleChecked}
          label="학부모에게 입퇴실 문자 보내기"
        />
      </div>
      <section className="enter-exit-manage__description">
        <p>문자 1건당 SMS 11원 / LMS 33원</p>
        <p>연락처를 기입한 대상에게만 전송됩니다.</p>
        <p>문자 내용은 선생님앱의 문자 관리 탭에서 수정 가능합니다.</p>
      </section>
    </div>
  );
};

export default observer(EnterExitManageModal);
