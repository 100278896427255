import { makeAutoObservable, runInAction } from "mobx";
import { AttendanceDTO, StudentWithAttendanceDTO } from "src/dto";
import { api } from "src/maxios";

class AttendanceService {
  _isViewStudentList: "VIEW" | "HIDDEN" = "VIEW"; // 학생을 보여줄지 여부

  constructor() {
    makeAutoObservable(this);
  }

  get isViewStudentList() {
    const option = localStorage.getItem("viewStudentList") as
      | "VIEW"
      | "HIDDEN"
      | null;

    if (!option) return "VIEW";

    return this._isViewStudentList;
  }

  initIsViewStudentList() {
    const option = localStorage.getItem("viewStudentList") as
      | "VIEW"
      | "HIDDEN"
      | null;

    this._isViewStudentList = option ? option : "VIEW";
    if (!option) {
      localStorage.setItem("viewStudentList", "VIEW");
    }
  }

  toggleViewStudentList() {
    const changed: AttendanceService["_isViewStudentList"] =
      this._isViewStudentList === "VIEW" ? "HIDDEN" : "VIEW";

    runInAction(() => {
      this._isViewStudentList = changed;
      localStorage.setItem("viewStudentList", changed);
    });
  }

  async fetchStudentAttendanceList(studentList: StudentWithAttendanceDTO[]) {
    const studentMap = new Map<
      StudentWithAttendanceDTO["id"],
      StudentWithAttendanceDTO
    >();
    studentList.forEach((student) => studentMap.set(student.id, student));

    const studentIdList = [...studentMap.keys()];
    const data = await api.getAttendances(studentIdList);
    if (!data[0]?.attendanceList || !data[0]?.attendanceList.length)
      return studentList;
    const [{ attendanceList }] = data;
    const studentWithAttendanceList = this.composeStudentWithAttendance(
      attendanceList,
      studentMap
    );
    return studentWithAttendanceList;
  }

  async putStudentEnter(student: StudentWithAttendanceDTO) {
    const { enterDatetime, quitDatetime, status, id } =
      await api.putAttendancesEnter(student);

    runInAction(() => {
      student.enterDatetime = enterDatetime;
      student.quitDatetime = quitDatetime;
      student.attendanceStatus = status;
      student.attendanceId = id;
    });
  }

  async putStudentQuit(student: StudentWithAttendanceDTO) {
    const { enterDatetime, quitDatetime, status, id } =
      await api.putAttendancesQuit(student);

    runInAction(() => {
      student.enterDatetime = enterDatetime;
      student.quitDatetime = quitDatetime;
      student.attendanceStatus = status;
      student.attendanceId = id;
    });
  }

  private composeStudentWithAttendance(
    attendanceList: AttendanceDTO[],
    studentMap: Map<StudentWithAttendanceDTO["id"], StudentWithAttendanceDTO>
  ) {
    attendanceList.forEach((attendance) => {
      const student = studentMap.get(attendance.studentId);
      if (student) {
        studentMap.get(attendance.studentId)!.enterDatetime =
          attendance.enterDatetime ? new Date(attendance.enterDatetime) : null;
        studentMap.get(attendance.studentId)!.quitDatetime =
          attendance.quitDatetime ? new Date(attendance.quitDatetime) : null;
        studentMap.get(attendance.studentId)!.attendanceStatus =
          attendance.status;
        studentMap.get(attendance.studentId)!.attendanceId = attendance.id;
      }
    });

    const studentWithAttendanceList = [...studentMap.values()];
    return studentWithAttendanceList;
  }
}

export default AttendanceService;
