import { academyDomain, userDomain } from "./domain";

export const authDaemon = async () => {
  try {
    await userDomain.autoLogin();
    if (userDomain.isAutoLogined) {
      await academyDomain.fetchAcademyInfo();
      window.location.pathname = "/main";
    }
  } catch (error) {
    userDomain.logout();
    window.location.pathname = "/login";
  }
};
