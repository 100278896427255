import axios from 'axios';

export { default as api } from './api.service';

const baseURL = (() => {
  if (process.env.REACT_APP_VERCEL_ENV === 'live') {
    return 'https://api.mathflat.com';
  }
  if (process.env.REACT_APP_VERCEL_ENV === 'staging') {
    return 'https://api-staging.mathflat.com';
  }

  return 'https://api-dev.mathflat.com';
})();

export const maxios = axios.create({
  baseURL,
  headers: {
    'content-Type': 'application/json',
  },
});

maxios.defaults.headers.common['x-platform'] = 'ATTENDANCE'; //TODO ATTENDANCE 교체
