import "./Login.scss";

import { observer } from "mobx-react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { authDaemon } from "src/AuthDaemon";
import { Container } from "src/components";
import { academyDomain, userDomain } from "src/domain";
import { modalStore } from "src/modal";
import ResetPasswordModal from "src/modal/ResetPasswordModal";

import loginImage from "/public/images/login-mockup@3x.png";
import { ReactComponent as MessageIcon } from "/public/images/svgs/icon_communication.svg";
import { ReactComponent as HeadsetIcon } from "/public/images/svgs/icon_headset_mic.svg";
import { ReactComponent as Logo } from "/public/images/svgs/icon_mathflat.svg";

const Login = () => {
  authDaemon();

  const [errorState, setErrorState] = React.useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data: Pick<req.login, "id" | "password">) => {
    try {
      await userDomain.login(data);
      await academyDomain.fetchAcademyInfo();
      setErrorState(false);
      reset();
      navigate("/main");
    } catch (error) {
      setErrorState(true);
    }
  };

  return (
    <Container>
      <section className="login__page">
        <div className="left__section">
          <div className="title-area">
            <Logo className="title-area__logo" />
            <p className="title-area__title">매쓰플랫은</p>
            <p className="title-area__title">출석도 간편하게</p>
          </div>
          <img
            className="example-image"
            src={loginImage}
            alt="매쓰플랫 이미지"
          />
        </div>
        <div className="right__section">
          <div className="login-form__wrap">
            <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
              <p className="login-form__title">로그인</p>
              <div className="login-form__input-wrap">
                <input
                  autoCapitalize="off"
                  className="login-form__input"
                  placeholder="아이디를 입력해주세요"
                  {...register("id")}
                />
                {errorState && (
                  <p className="login-form__input--error-status-message">
                    아이디를 다시 확인해주세요.
                  </p>
                )}
              </div>
              <div className="login-form__input-wrap">
                <input
                  type="password"
                  className="login-form__input"
                  placeholder="비밀번호를 입력해주세요"
                  {...register("password")}
                />
                {errorState && (
                  <span className="login-form__input--error-status-message">
                    비밀번호를 다시 확인해주세요.
                  </span>
                )}
              </div>

              <button
                type="submit"
                className="login-form__submit"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                시작하기
              </button>
            </form>
            <button
              className="login-form__find-password-link"
              onClick={() => modalStore.openModal(<ResetPasswordModal />)}
            >
              비밀번호 찾기
            </button>
          </div>

          <address className="customer-guide-address">
            <a
              className="customer-guide-address__link phone-link"
              href="tel:1670-1930"
            >
              <HeadsetIcon className="customer-guide__icon" />
              고객센터 1670 - 1930
            </a>
            |
            <button
              className="customer-guide-address__link kakao-link"
              onClick={() =>
                window.open("https://pf.kakao.com/_jxcuwC", "_blank")
              }
            >
              <MessageIcon className="customer-guide__icon" />
              카카오톡 문의하기
            </button>
          </address>
        </div>
      </section>
    </Container>
  );
};

export default observer(Login);
