import qs from "query-string";
import { AttendanceDTO, StudentDTO, StudentWithAttendanceDTO } from "src/dto";
import { util } from "src/utils";

class AdapterService {
  // 서버로부터
  to = {
    enter(student: StudentWithAttendanceDTO): req.AttendanceEnter {
      return {
        studentIdList: [student.id],
        datetime: new Date(new Date().getTime() + 9 * 60 * 60 * 1000),
        sentForParent: !!student.parent.phone,
      };
    },
    quit(student: StudentWithAttendanceDTO): req.AttendanceQuit {
      return {
        idList: [student.attendanceId!],
        datetime: new Date(new Date().getTime() + 9 * 60 * 60 * 1000),
        sentForParent: !!student.parent.phone,
      };
    },
    studentIds: (studentIdList: StudentDTO["id"][]) => {
      return qs.stringify(
        {
          studentIdList,
          startDate: util.getStringToday(),
          endDate: util.getStringToday(),
          page: 0,
          size: 1000000,
          sort: ["name", "ASC"],
          status: "ACTIVE",
        },
        {
          arrayFormat: "comma",
        }
      );
    },
  };

  // 서버쪽으로
  from = {
    studentWithAttendance: (student: StudentDTO) => {
      return {
        ...student,
        enterDatetime: null,
        quitDatetime: null,
        attendanceStatus:
          "QUIT" as StudentWithAttendanceDTO["attendanceStatus"],
        attendanceId: null,
      };
    },
    attendanceInfo: (response: AttendanceDTO[]) => {
      const [{ enterDatetime, quitDatetime, status, id }] = response;
      return {
        enterDatetime,
        quitDatetime,
        status,
        id,
      };
    },
  };
}

export default AdapterService;
