import { makeAutoObservable, runInAction } from 'mobx';
import { AcademyDTO } from 'src/dto';
import { api } from 'src/maxios';

class AcademyService implements AcademyDTO {
  status: 'FREE_INPROGRESS' | 'PAY_INPROGRESS' = 'FREE_INPROGRESS';
  name = '';
  email = '';
  logoUrl = '';
  initialized = false;
  cellPhone = '';
  freeStartDate = '';
  freeEndDate = '';
  payStartDate = '';
  payEndDate = '';
  logoOperation: 'DELETE' | 'REPLACE' | 'REPLACE_DEFAULT' = 'REPLACE_DEFAULT';
  worksheetShareFlag = false; // 다른 학원에 학습지를 공개했는지 여부
  metadata = {
    attendanceSmsService: 'true' as 'true' | 'false',
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchAcademyInfo() {
    const data = await api.getAcademies();

    runInAction(() => {
      Object.assign(this, data);
    });
  }
  async patchAcademyMetadata(changedSmsSent: boolean) {
    api.patchMetadata({
      key: 'ATTENDANCE_SMS_SERVICE',
      value: changedSmsSent ? 'true' : 'false',
    });
  }
}

export default AcademyService;
