import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";

import { modalStore } from "../";

const CustomModal = () => {
  return (
    <Modal
      isOpen={modalStore.isOpen}
      onRequestClose={() => {
        modalStore.closeModal();
      }}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      {modalStore.contentNode}
    </Modal>
  );
};

export default observer(CustomModal);

const modalStyle: React.ComponentProps<typeof Modal>["style"] = {
  overlay: {
    background: "rgba(0,0,0,0.5)",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    zIndex: 99,
    overflow: "auto",
  },
  content: {
    overflow: "auto",
    position: "relative",
    padding: 0,
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "5px",
    boxShadow: `0 0 50px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: "100%",
  },
};
