import "./ResetPasswordNotice.scss";

import { observer } from "mobx-react";
import React from "react";

import { modalStore } from "..";

const ResetPasswordNotice = () => {
  return (
    <div className="reset__notice--wrap">
      <h2 className="notice__title">메일함을 확인해주세요.</h2>
      <p className="notice__sub-text">
        입력하신 이메일 주소로 비밀번호 재설정 링크를 발송하였습니다.
      </p>
      <button
        className="confirm__button"
        onClick={() => {
          modalStore.closeModal();
        }}
      >
        확인
      </button>
    </div>
  );
};

export default observer(ResetPasswordNotice);
