import { makeAutoObservable, runInAction } from "mobx";
import { UserDTO } from "src/dto";
import { api, maxios } from "src/maxios";

import { academyDomain } from "..";

class UserService implements UserDTO {
  id = ""; // 로그인할때 사용하는 아이디
  academyId = ""; //  학원 아이디
  relationId = ""; // 선생님 아이디
  token = "";

  constructor() {
    makeAutoObservable(this);
  }

  get localStorageInfo() {
    const token = this.getLocalstorageToken;
    const relationId = this.getLocalStorageTeacherId;
    if (!token || !relationId) return;

    maxios.defaults.headers.common["x-auth-token"] = token;

    return {
      token,
      relationId,
    };
  }

  clearService() {
    localStorage.removeItem("x-auth-token");
    localStorage.removeItem("teacherId");
    this.relationId = "";
    this.token = "";
    maxios.defaults.headers.common["x-auth-token"] = "";
  }

  async postLogin(params: req.login) {
    const data = await api.postLogin(params);
    maxios.defaults.headers.common["x-auth-token"] = data.token;

    return data;
  }

  async autoLogin({
    token,
    relationId,
  }: {
    token: string;
    relationId: string;
  }) {
    const { id } = await api.ping();
    this.saveStorage({ token, relationId, id });
    await academyDomain.fetchAcademyInfo();
  }

  async resetPassword(data: req.reset) {
    await api.postResetPassword(data);
  }

  saveStorage({ token, relationId, id }: Omit<UserDTO, "academyId">) {
    runInAction(() => {
      this.token = token;
      this.relationId = relationId;
      this.id = id;
      this.setLocalStorageToken = token;
      this.setLocalStorageTeacherId = relationId;
    });
  }

  private set setLocalStorageToken(token: UserDTO["token"]) {
    localStorage.setItem("x-auth-token", token);
  }

  private get getLocalstorageToken() {
    return localStorage.getItem("x-auth-token");
  }

  private set setLocalStorageTeacherId(teacherId: UserDTO["relationId"]) {
    localStorage.setItem("teacherId", teacherId);
  }

  private get getLocalStorageTeacherId() {
    return localStorage.getItem("teacherId");
  }
}

export default UserService;
