import "./Container.scss";

import { motion, MotionProps } from "framer-motion";
import React from "react";

const variants = {
  hidden: { opacity: 0, x: 0, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: "-100vw", y: 0 },
};

type Props = {
  children: React.ReactNode;
} & MotionProps;

const Container = ({ children, ...props }: Props) => {
  return (
    <motion.main
      variants={variants}
      initial="hidden"
      animate="enter"
      exit="exit"
      className="container"
      transition={{ type: "linear" }}
      {...props}
    >
      {children}
    </motion.main>
  );
};

export default Container;
