import { makeAutoObservable, runInAction } from "mobx";
import React from "react";

class ModalStore {
  private isModalOpen = false;
  private content: React.ReactNode | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isOpen() {
    return this.isModalOpen;
  }

  get contentNode() {
    return this.content;
  }

  private set contentNode(content: React.ReactNode) {
    runInAction(() => {
      this.content = content;
    });
  }

  toggleModalState() {
    runInAction(() => {
      this.isModalOpen = !this.isModalOpen;
    });
  }

  openModal(content: React.ReactNode) {
    runInAction(() => {
      this.contentNode = content;
      this.isModalOpen = true;
    });
  }

  closeModal() {
    runInAction(() => {
      this.content = null;
      this.isModalOpen = false;
    });
  }
}

const modalStore = new ModalStore();
export default modalStore;
