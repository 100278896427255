import {
  AcademyDTO,
  AcademyMetadataDTO,
  AttendanceDTO,
  StudentDTO,
  StudentWithAttendanceDTO,
  UserDTO,
} from "src/dto";
import { DefaultResponse, Pagination } from "types/default";

import { maxios } from "./";
import AdapterService from "./adapter.service";

class ApiService {
  constructor(private adapter: AdapterService) {}

  async ping() {
    const {
      data: { data },
    } = await maxios.get<DefaultResponse<UserDTO>>("/ping");
    return data;
  }
  async getAcademies() {
    const {
      data: { data },
    } = await maxios.get<DefaultResponse<AcademyDTO>>("/academies");
    return data;
  }
  async getStudents() {
    const {
      data: {
        data: { content },
      },
    } = await maxios.get<Pagination<StudentDTO[]>>(
      `/students?page=0&size=1000000&status=ACTIVE`
    );

    return content.map(this.adapter.from.studentWithAttendance);
  }

  async getAttendances(studentIdList: StudentDTO["id"][]) {
    const queryString = this.adapter.to.studentIds(studentIdList);

    const {
      data: { data },
    } = await maxios.get<
      DefaultResponse<
        {
          date: `${number}-${number}-${number}`; // yyyy-MM-dd
          attendanceList: AttendanceDTO[];
        }[]
      >
    >(`/attendances?${queryString}`);
    return data;
  }

  async postLogin(params: req.login) {
    const {
      data: { data },
    } = await maxios.post<DefaultResponse<UserDTO>>("/login", params);

    return data;
  }

  async postResetPassword(data: req.reset) {
    await maxios.post("/password/reset-token", data);
  }

  async putAttendancesEnter(student: StudentWithAttendanceDTO) {
    const params = this.adapter.to.enter(student);
    const {
      data: { data },
    } = await maxios.put<DefaultResponse<AttendanceDTO[]>>(
      "/attendances/enter",
      params
    );
    return this.adapter.from.attendanceInfo(data);
  }
  async putAttendancesQuit(student: StudentWithAttendanceDTO) {
    const params = this.adapter.to.quit(student);
    const {
      data: { data },
    } = await maxios.put<DefaultResponse<AttendanceDTO[]>>(
      "/attendances/quit",
      params
    );

    return this.adapter.from.attendanceInfo(data);
  }

  async patchMetadata(data: AcademyMetadataDTO) {
    await maxios.patch("/academies/metadata", data);
  }
}

const api = new ApiService(new AdapterService());
export default api;
