import "./WarningModal.scss";

import React from "react";

type Props = {
  text: React.ReactNode;
  onConfirm: () => void;
};

const WarningModal = ({ text, onConfirm }: Props) => {
  return (
    <div className="warning__modal-wrap">
      <div>{text}</div>
      <button
        className="check__button"
        onClick={() => {
          onConfirm();
        }}
      >
        확인
      </button>
    </div>
  );
};

export default WarningModal;
