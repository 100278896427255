import "./Main.scss";

import { observer } from "mobx-react";
import React from "react";
import { Container } from "src/components";
import { attendanceDomain } from "src/domain";

import Attendance from "./Attendance";
import StudentList from "./StudentList";

const Main = () => {
  React.useEffect(() => {
    attendanceDomain.fetchStudentAttendanceList();
  }, []);

  return (
    <Container>
      <div className="main__page">
        <Attendance />
        {attendanceDomain.isViewList === "VIEW" && <StudentList />}
      </div>
    </Container>
  );
};

export default observer(Main);
