import "./Attendance.scss";

import { observer } from "mobx-react";
import React from "react";
import { toast } from "react-toastify";
import { academyDomain, attendanceDomain } from "src/domain";
import { modalStore, SettingModal, WarningModal } from "src/modal";
import { util } from "src/utils";

import { ReactComponent as DeleteIcon } from "/public/images/svgs/icon_delete.svg";
import { ReactComponent as RefreshIcon } from "/public/images/svgs/icon_refresh.svg";
import { ReactComponent as SettingIcon } from "/public/images/svgs/icon_setting.svg";
import enterSound from "/public/voice/MathFLAT_Attendance_src_main_res_raw_sound_attend.wav";

const Attendance = () => {
  const onSettingClick = () => {
    modalStore.openModal(<SettingModal />);
  };

  const [playSound, setPlaySound] = React.useState(false);
  const [koTime, setKoTime] = React.useState(util.getTodayKo());
  const [attendanceCodeList, setAttendanceCode] = React.useState("");

  React.useEffect(() => {
    const timer = setInterval(() => {
      setKoTime(util.getTodayKo());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (attendanceCodeList.length !== 4) return;
    const target = attendanceDomain.getAttendanceTarget(attendanceCodeList);
    if (!target) {
      modalStore.openModal(
        <WarningModal
          text="출석코드를 다시 확인해주세요."
          onConfirm={() => {
            modalStore.closeModal();
            setAttendanceCode("");
          }}
        />
      );
    }
  }, [attendanceCodeList]);

  const onNumberClick = (code: number) => {
    if (attendanceCodeList.length === 4) return;

    const codeStr = attendanceCodeList.concat(`${code}`);

    setAttendanceCode(codeStr);
  };

  const onDeleteClick = () => {
    if (attendanceCodeList.length === 0) return;
    const codeStr = attendanceCodeList.substring(
      0,
      attendanceCodeList.length - 1
    );
    setAttendanceCode(codeStr);
  };

  const onAttendanceClick = async () => {
    try {
      const attendanceStatus = await attendanceDomain.fetchStudentAttendance(
        attendanceCodeList
      );
      if (!attendanceStatus) return;

      const text =
        attendanceStatus === "ENTER"
          ? "출석 처리되었습니다."
          : "퇴실 처리되었습니다.";
      setPlaySound(true);
      toast(text);
      setTimeout(() => {
        setPlaySound(false);
      }, 1000);
      setAttendanceCode("");
    } catch (error) {
      toast("출석 코드를 다시 확인해주세요.");
      setAttendanceCode("");
    }
  };

  return (
    <section
      className={`attendance__input--wrap ${
        attendanceDomain.isViewList === "HIDDEN" ? "full" : ""
      }`}
    >
      <div className="attendance__header">
        <button className="header__config" onClick={() => onSettingClick()}>
          <SettingIcon />
          설정
          {playSound && <audio autoPlay src={enterSound}></audio>}
        </button>
        <time className="header__time">{koTime}</time>
        <button
          className="header__refresh-button"
          onTouchEnd={() => window.location.reload()}
        >
          <div className="refresh__circle">
            <RefreshIcon />
          </div>
        </button>
      </div>
      <div className="attendance__body">
        <img
          className="academy__logo"
          src={academyDomain.academyLogo}
          alt="학원 로고 이미지"
        />
        <div className="inserted-numeric__list">
          <div className="inserted-numeric__item">
            {attendanceCodeList[0] || ""}
          </div>
          <div className="inserted-numeric__item">
            {attendanceCodeList[1] || ""}
          </div>
          <div className="inserted-numeric__item">
            {attendanceCodeList[2] || ""}
          </div>
          <div className="inserted-numeric__item">
            {attendanceCodeList[3] || ""}
          </div>
        </div>
        <div className="numeric__button-list">
          {new Array(9).fill(null).map((_, i) => {
            return (
              <button
                key={i}
                className="numeric__item"
                data-testid={`numeric-${i + 1}`}
                onTouchEnd={(e) => {
                  e.stopPropagation();
                  onNumberClick(i + 1);
                }}
              >
                {i + 1}
              </button>
            );
          })}
          <div className="numeric__item"></div>
          <button
            className="numeric__item"
            onTouchEnd={(e) => {
              e.stopPropagation();
              onNumberClick(0);
            }}
          >
            0
          </button>
          <button
            className="numeric__item back__button"
            onTouchEnd={() => onDeleteClick()}
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      <button
        className="attendance__submit-button"
        disabled={attendanceCodeList.length !== 4}
        onTouchEnd={() => onAttendanceClick()}
      >
        {attendanceDomain.getAttendanceText(attendanceCodeList)}
      </button>
    </section>
  );
};

export default observer(Attendance);
