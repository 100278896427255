import './Toggle.scss';

import { uniqueId } from 'lodash';
import React from 'react';

type Props = {
  id?: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const Toggle = ({ id = uniqueId(), checked, onChange }: Props) => {
  return (
    <>
      <input
        id={id}
        className="toggle"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="toggle-label-default" />
    </>
  );
};

const ToggleWithLabel = ({
  id = uniqueId(),
  checked,
  onChange,
  label,
}: Props & { label: React.ReactNode }) => {
  return (
    <div className="toggle-container">
      <Toggle checked={checked} onChange={onChange} id={id} />
      <label htmlFor={id} className="toggle-label">
        {label}
      </label>
    </div>
  );
};

Toggle.Label = ToggleWithLabel;

export default Toggle;
