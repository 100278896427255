import { makeAutoObservable } from "mobx";
import { StudentWithAttendanceDTO } from "src/dto";
import { api } from "src/maxios";
import { util } from "src/utils";

class StudentService {
  studentWithAttendanceList: StudentWithAttendanceDTO[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  async fetchStudents() {
    const attendanceStudentList = await api.getStudents();
    return util.sortStudentList(attendanceStudentList);
  }
}

export default StudentService;
