import { makeAutoObservable } from 'mobx';

import mathflatLogoImage from '/public/images/mathflat_logo.png';

import AcademyService from './Academy.service';

class AcademyDomain {
  constructor(private academyService: AcademyService) {
    makeAutoObservable(this);
  }

  get academyLogo() {
    return this.academyService.logoUrl || mathflatLogoImage;
  }
  get hasCellPhone() {
    return !!this.academyService.cellPhone;
  }
  get isSmsSent() {
    return this.academyService.metadata.attendanceSmsService === 'true';
  }

  updateAcademyMetadata(changedSmsSent: boolean) {
    this.academyService.metadata.attendanceSmsService = changedSmsSent
      ? 'true'
      : 'false';
  }

  async fetchAcademyInfo() {
    await this.academyService.fetchAcademyInfo();
  }
  async patchAcademyMetadata(changedSmsSent: boolean) {
    await this.academyService.patchAcademyMetadata(changedSmsSent);
  }
}

const academyDomain = new AcademyDomain(new AcademyService());
export default academyDomain;
