import { makeAutoObservable, runInAction } from "mobx";

import UserService from "./User.service";

class User {
  constructor(private userService: UserService) {
    makeAutoObservable(this);
  }

  get isAutoLogined() {
    return !!this.userService.token;
  }

  async login(params: Pick<req.login, "id" | "password">) {
    const data = await this.userService.postLogin(params);
    this.userService.saveStorage(data);
  }

  async autoLogin() {
    const data = this.userService.localStorageInfo;
    if (!data) return;
    this.userService.autoLogin(data);
  }

  async confirmManageOption(password: string) {
    await this.userService.postLogin({ id: this.userService.id, password });
  }

  async resetPassword(data: req.reset) {
    await this.userService.resetPassword(data);
  }

  logout() {
    runInAction(() => {
      this.userService.clearService();
      window.location.pathname = "/login";
    });
  }
}

const userDomain = new User(new UserService());
export default userDomain;
