import "./ResetPasswordModal.scss";

import { observer } from "mobx-react-lite";
import React from "react";
import { useForm } from "react-hook-form";
import { userDomain } from "src/domain";

import { modalStore } from "..";
import ResetPasswordNotice from "./ResetPasswordNotice";

const ResetPasswordModal = () => {
  const { register, handleSubmit, reset } = useForm();
  const [errorState, setErrorState] = React.useState(false);

  const onCancelClick = () => {
    reset();
    modalStore.closeModal();
  };

  const onSubmit = async (data: { loginId: string; email: string }) => {
    try {
      await userDomain.resetPassword(data);
      setErrorState(false);
      modalStore.closeModal();
      modalStore.openModal(<ResetPasswordNotice />);
    } catch (error) {
      setErrorState(true);
    }
  };

  return (
    <section className="reset__modal">
      <h2 className="reset__title">비밀번호 찾기</h2>
      <p className="reset__title--sub">
        아이디와 가입하실 때 등록하신 이메일 주소를 입력해주세요.
      </p>
      <form className="reset__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__input--wrap">
          <p className="form__label">아이디</p>
          <div>
            <input
              autoCapitalize="off"
              className="form__id-input"
              type="text"
              placeholder="아이디를 입력해주세요"
              {...register("loginId")}
            />
            {errorState && (
              <p className="form--error-status-message">
                아이디를 다시 확인해주세요.
              </p>
            )}
          </div>
        </div>
        <div className="form__input--wrap">
          <p className="form__label">이메일 주소</p>
          <div>
            <input
              className="form__email-input"
              type="email"
              placeholder="이메일을 입력해주세요"
              {...register("email")}
            />
            {errorState && (
              <p className="form--error-status-message">
                이메일을 다시 확인해주세요.
              </p>
            )}
          </div>
        </div>
        <div className="form__submit-wrap">
          <button className="cancel__button" onClick={onCancelClick}>
            취소
          </button>
          <button className="submit__button" type="submit">
            다음
          </button>
        </div>
      </form>
    </section>
  );
};

export default observer(ResetPasswordModal);
