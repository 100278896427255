import _ from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";

import { studentDomain } from "..";
import AttendanceService from "./Attendance.service";

export class Attendance {
  constructor(private attendanceService: AttendanceService) {
    makeAutoObservable(this);
  }

  get isViewList(): "HIDDEN" | "VIEW" {
    return this.attendanceService.isViewStudentList;
  }

  initIsViewStudentList() {
    this.attendanceService.initIsViewStudentList();
  }

  getAttendanceTarget = computedFn((codeStr: string) => {
    if (codeStr.length !== 4) return undefined;
    const target = studentDomain.studentList.find(
      (student) => student.attendanceCode === codeStr
    );
    return target;
  });

  getAttendanceText = computedFn((codeStr: string) => {
    if (codeStr.length !== 4) return "출석";

    const target = this.getAttendanceTarget(codeStr);

    if (target) {
      return target.attendanceStatus === "QUIT" ? "출석" : "퇴실";
    }

    return "출석";
  });

  async fetchStudentAttendanceList() {
    const studentList = await studentDomain.fetchStudents();
    const studentWithAttendanceList =
      await this.attendanceService.fetchStudentAttendanceList(studentList);
    runInAction(() => {
      studentDomain.studentList = _.orderBy(
        studentWithAttendanceList,
        ["name"],
        ["asc"]
      );
    });
  }

  async fetchStudentAttendance(codeStr: string) {
    const target = this.getAttendanceTarget(codeStr);
    if (!target) return;

    target.attendanceStatus === "QUIT"
      ? await this.attendanceService.putStudentEnter(target)
      : await this.attendanceService.putStudentQuit(target);

    const removedList = studentDomain.studentList.filter(
      (student) => student.id !== target.id
    );
    const targetRaisedList = [target, ...removedList];
    runInAction(() => {
      studentDomain.studentList = targetRaisedList;
    });
    return target.attendanceStatus;
  }

  toggleViewStudentList() {
    runInAction(() => {
      this.attendanceService.toggleViewStudentList();
    });
  }
}

const attendanceDomain = new Attendance(new AttendanceService());
export default attendanceDomain;
