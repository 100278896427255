import './SettingModal.scss';

import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { academyDomain, attendanceDomain, userDomain } from 'src/domain';

import { ReactComponent as CloseIcon } from '/public/images/svgs/icon_close.svg';

import { ConfirmModal, modalStore } from '..';
import EnterExitManageModal from '../EnterExitManageModal';

const SettingModal = () => {
  const navigate = useNavigate();

  const onConfirm = (guide: React.ReactNode, onConfirm: () => void) => {
    modalStore.closeModal();
    modalStore.openModal(<ConfirmModal guide={guide} onConfirm={onConfirm} />);
  };

  const onChangeViewStudent = () => {
    modalStore.closeModal();
    attendanceDomain.toggleViewStudentList();
  };

  const closeCurrentModalAndOpenEnterExitManageModal = () => {
    modalStore.closeModal();
    modalStore.openModal(<EnterExitManageModal />);
  };

  return (
    <div className="setting__modal">
      <h3 className="setting__title">설정</h3>
      <button
        className="setting__modal--close"
        onClick={() => modalStore.closeModal()}
      >
        <CloseIcon />
      </button>
      <div className="setting__option--wrap">
        <button
          className="option__item hide__option--isMobile"
          onClick={() => {
            onConfirm(
              <>
                입퇴실 기록이 보이는 영역을&nbsp;
                {attendanceDomain.isViewList === 'VIEW'
                  ? '숨기려면'
                  : '보이려면'}
                <br />
                매쓰플랫 선생님 앱 비밀번호를 입력해주세요.
              </>,
              () => onChangeViewStudent()
            );
          }}
        >
          입퇴실 기록&nbsp;
          {attendanceDomain.isViewList === 'VIEW' ? '숨기기' : '보여주기'}
        </button>
        <button
          className="option__item"
          disabled={!academyDomain.hasCellPhone}
          onClick={() => {
            onConfirm(
              <>
                입퇴실 문자를 설정하려면
                <br />
                매쓰플랫 선생님 앱 비밀번호를 입력해주세요.
              </>,
              closeCurrentModalAndOpenEnterExitManageModal
            );
          }}
        >
          입퇴실 문자 관리
        </button>
        <button
          className="option__item"
          onClick={() => {
            onConfirm(
              <>
                학생 로그인 아이디를 관리하려면 <br />
                매쓰플랫 선생님 앱 비밀번호를 입력해주세요.
              </>,
              () => {
                navigate('/student-list');
                modalStore.closeModal();
              }
            );
          }}
        >
          학생 로그인 아이디 관리
        </button>
        <button
          className="option__item"
          onClick={() => {
            onConfirm(
              <>
                로그아웃을 하기 위해
                <br />
                매쓰플랫 선생님 앱 비밀번호를 입력해주세요.
              </>,
              () => {
                userDomain.logout();
                modalStore.closeModal();
              }
            );
          }}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default observer(SettingModal);
