import "./ConfirmModal.scss";

import { observer } from "mobx-react";
import React from "react";
import { useForm } from "react-hook-form";
import { userDomain } from "src/domain";

import { modalStore, WarningModal } from "..";

type Props = {
  guide: React.ReactNode;
  onConfirm: () => void;
};

const ConfirmModal = ({ guide, onConfirm }: Props) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async ({ password }: { password: string }) => {
    try {
      await userDomain.confirmManageOption(password);
      onConfirm();
    } catch (error) {
      modalStore.openModal(<WarningModal text={"비밀번호가 틀렸습니다."} onConfirm={() => modalStore.closeModal()} />);
    }
  };

  return (
    <div className="confirm__modal">
      <div className="guide__text">{guide}</div>
      <form onSubmit={handleSubmit(onSubmit)} className="confirm__form">
        <input
          type="password"
          {...register("password")}
          className="form__input"
          placeholder="비밀번호를 입력해주세요."
        />
        <div className="form__button--wrap">
          <button
            type="reset"
            className="form__button--reset"
            onClick={() => modalStore.closeModal()}
          >
            취소
          </button>
          <button type="submit" className="form__button--submit">
            확인
          </button>
        </div>
      </form>
    </div>
  );
};

export default observer(ConfirmModal);
