import "./StudentList.scss";

import { observer } from "mobx-react";
import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { studentDomain } from "src/domain";
import { util } from "src/utils";

const StudentList = () => {
  return (
    <section className="student__list--wrap">
      <div className="student__header">
        <p className="student__name">학생</p>
        <p className="enter__history">입실</p>
        <p className="exit__history">퇴실</p>
      </div>
      <Scrollbars className="student__table">
        {studentDomain.hasStudentList ? (
          studentDomain.studentList.map((student) => {
            return (
              <div className="table__row" key={student.id}>
                <div className="student__name">
                  <div
                    className={`student__dot ${(() => {
                      if (!student.enterDatetime && !student.quitDatetime)
                        return "";

                      return student.attendanceStatus === "ENTER"
                        ? "student_attendance--enter"
                        : "student_attendance--quit";
                    })()}`}
                  />
                  {student.name}
                </div>
                <div className="enter__history">
                  {student.enterDatetime
                    ? util.getDateToString(
                        student.enterDatetime as unknown as Date
                      )
                    : "-"}
                </div>
                <div className="exit__history">
                  {student.quitDatetime
                    ? util.getDateToString(
                        student.quitDatetime as unknown as Date
                      )
                    : "-"}
                </div>
              </div>
            );
          })
        ) : (
          <p>오늘은 학생이 없습니다. </p>
        )}
      </Scrollbars>
    </section>
  );
};

export default observer(StudentList);
