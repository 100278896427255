import "./DetailedStudentList.scss";

import { observer } from "mobx-react";
import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router";
import { studentDomain } from "src/domain";
import { util } from "src/utils";

const DetailedStudentList = () => {
  const navigate = useNavigate();

  return (
    <section className="detailed-student-list__page">
      <div className="student-list__header">
        <div className="student__grade">학년</div>
        <div className="student__name">학생</div>
        <div className="student__phone">휴대폰 번호</div>
        <div className="login__id">로그인 아이디</div>
        <div className="student__attendanceCode">출결번호</div>
      </div>
      <Scrollbars className="student-list__table">
        {studentDomain.studentList.map((student) => {
          return (
            <div className="table__row" key={student.loginId}>
              <div className="student__grade">
                {util.koGrade(student.grade)}
              </div>
              <div className="student__name">{student.name}</div>
              <div className="student__phone">{student.phone}</div>
              <div className="login__id">{student.loginId}</div>
              <div className="student__attendanceCode">
                {student.attendanceCode}
              </div>
            </div>
          );
        })}
      </Scrollbars>
      <button className="close__button" onClick={() => navigate(-1)}>
        닫기
      </button>
    </section>
  );
};

export default observer(DetailedStudentList);
