import { makeAutoObservable, runInAction } from "mobx";
import { StudentWithAttendanceDTO } from "src/dto";
import { util } from "src/utils";

import StudentService from "./Student.service";

class Student {
  constructor(private studentService: StudentService) {
    makeAutoObservable(this);
  }

  get hasStudentList() {
    return !!this.studentList.length;
  }

  get studentList() {
    return this.studentService.studentWithAttendanceList || [];
  }

  set studentList(studentWithAttendanceList: StudentWithAttendanceDTO[]) {
    runInAction(() => {
      this.studentService.studentWithAttendanceList = util.sortStudentList(
        studentWithAttendanceList
      );
    });
  }

  async fetchStudents() {
    return await this.studentService.fetchStudents();
  }
}

const studentDomain = new Student(new StudentService());
export default studentDomain;
